import React, { useState } from "react";
// import { Link, Outlet } from "react-router-dom";
// import { useDispatch } from "react-redux";

import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,

} from "react-router-dom";
import "./FiveSAudit.css";
import Buttons from "../../components/Buttons/Buttons";
import Header from "../../components/header/Header";
import Sort from "../Sort/Sort";
import SetInOrder from "../SetInOrder/SetInOrder";
import Shine from "../Shine/Shine";
import Standardize from "../Standardize/Standardize";
import Sustain from "../Sustain/Sustain";
// import {handleEmptyGlobalStateButton  } from "../../Store/redux-api/GlobalApiCalls"

const FiveSAudit = () => {
  const match = useRouteMatch();
  // const  [isTrue, setIsTrue]= useState(true)
  const [userData, setUserData] = useState("");
  const [isActive, setIsActive] = useState(false)
  const location = useLocation();
  const data = [
    {
      text: "Sort",
      path: "/sort",
    },
    {
      text: "Set In Order",
      path: "/setInOrder",
    },
    {
      text: "Shine",
      path: "/shine",
    },
    {
      text: "Standardize",
      path: "/standardize",
    },
    {
      text: "Sustain",
      path: "/sustain",
    },
  ];


  return (
    <div>
      <Header />
      <div className="mx-2">

        <div className="container-fluid buttons__card d-flex justify-content-between my-lg-4 ">
          <div className="row w-100">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex justify-content-around w-100 flex-wrap">
                {data.map((item, index) => {
                  return (
                    <Link  
                    // value={isActive} onClick={handlebuttontogle}
                      style={{ cursor: "not-allowed", pointerEvents: 'none', color: 'grey' }}
                      key={index} to={`/dashboard/user${item.path}`}>
                      <Buttons text={item.text} coloring={"#fff"} />
                    </Link>
                  );
                })}


              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="routing mx-2 rounded-2">
        {/* <Outlet /> */}
        <Switch>
          <Route path={match.url + "/sort"}>
            <Sort />
          </Route>
          <Route path={match.url + "/setInOrder"}>
            <SetInOrder />
          </Route>
          <Route path={match.url + "/shine"}>
            <Shine />
          </Route>
          <Route path={match.url + "/standardize"}>
            <Standardize />{" "}
          </Route>
          <Route path={match.url + "/sustain"}>
            <Sustain />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default FiveSAudit;
