// import { SaveOutlined, TextSnippetOutlined } from "@mui/icons-material";
// import React from "react";
import React from "react";

// import Sort from "../Sort/Sort";
// import SetInOrder from '../SetInOrder/SetInOrder'
// import Shine from '../Shine/Shine'
// import Standardize from '../Standardize/Standardize'
// import Sustain from '../Sustain/Sustain'
// import { Routes } from "react-router-dom";
// import { Route } from "react-router-dom";
// import { Link, Outlet,} from "react-router-dom";
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";




import "./FiveSAudit.css";
import Buttons from "../../components/Buttons/Buttons";
// import Header from "../../components/header/Header";
import AdminHeader from "../../components/header/AdminHeader";
import AdminSort from "../Sort/AdminSort";
import AdminSetInOrder from "../SetInOrder/AdminSetInOrder";
import AdminShine from "../Shine/AdminShine";
import AdminStandardize from "../Standardize/AdminStandardize";
import AdminSustain from "../Sustain/AdminSustain";

const AdminFiveSAudit = () => {
  const match = useRouteMatch();


  const data = [
    {
      text: "Sort",
      path: "/AdminSort"
    },
    {
      text: "Set In Order",
      path: "/AdminSetInOrder"
    },
    {
      text: "Shine",
      path: "/AdminShine"
    },
    {
      text: "Standerdize",
      path: "/AdminStandardize"
    },
    {
      text: "Sustain",
      path: "/AdminSustain"
    },
  ]
  return (
    <div>
      {/* Header */}
      <AdminHeader />
      {/* Buttons  */}
      <div className="mx-2">

        <div className="container-fluid buttons__card d-flex justify-content-between my-4 ">
          <div className="row w-100">
            <div className=" ">
              <div className="d-flex justify-content-evenly w-100 flex-wrap">
                {data.map(
                  (item, index) => {
                    return (
                      <Link key={index} to={`/dashboard/Admin${item.path}`}>
                        <Buttons style={{ color: 'orange' }} text={item.text} />
                      </Link>
                    );
                  }
                )}
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="d-flex justify-content-evenly">
            <div className="icons__button my-1">
              <SaveOutlined />
            </div>
            <div className="icons__button my-1">
              <TextSnippetOutlined />
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
      <div className="routing mx-2 rounded-2">

        {/* <Outlet /> */}
        {/* <Routes>
          <Route path="/5sAudit/sort" element={<Sort />} />
          <Route path="/5sAudit/setInOrder" element={<SetInOrder />} />
          <Route path="/5sAudit/shine" element={<Shine />} />
          <Route path="/5sAudit/standardize" element={<Standardize />} />
          <Route path="/5sAudit/sustain" element={<Sustain />} />
        </Routes> */}
        <Switch>
          {/* <Route exact path={match.url + ""}> */}
          <Route path={match.url + "/AdminSort"}>
            <AdminSort />
          </Route>
          <Route path={match.url + "/AdminSetInOrder"}>
            <AdminSetInOrder />
          </Route>
          <Route path={match.url + "/AdminShine"}>
            <AdminShine />
          </Route>
          <Route path={match.url + "/AdminStandardize"}>
            <AdminStandardize />{" "}
          </Route>
          <Route path={match.url + "/AdminSustain"}>
            <AdminSustain />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AdminFiveSAudit;
