import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import ComboBox from "../ComboBox/ComboBox";
// import Area from "../Area/Area";
import "./header.css";

const AdminHeader = () => {
  return (
    <div className="mx-2">
      <Card
        className="d-flex align-items-center justify-content-center header__card"
      >
        {/* <Card.Header> */}
        <div className="w-100 h-100 d-flex justify-content-between">
          <div className="d-flex my-1">
            <Link to={"/dashboard"} className="text-decoration-none text-dark text-center">
              <h2>5S Audit Execution</h2>
            </Link>
          </div>

        </div>

      </Card>
    </div>
  );
};

export default AdminHeader;
