import {
  getAdminStanderdizeStart,
  getAdminStanderdizeSuccess,
  getAdminStanderdizeFailure,
  deleteAdminStanderdizeStart,
  deleteAdminStanderdizeSuccess,
  deleteAdminStanderdizeFailure,
  updateAdminStanderdizeStart,
  updateAdminStanderdizeSuccess,
  updateAdminStanderdizeFailure,
  addAdminStanderdizeStart,
  addAdminStanderdizeSuccess,
  addAdminStanderdizeFailure,
} from "../reducers/AdminStanderdizeReducer";
import {
  ADD_QUES_STANERDIZE,
  UPDATE_QUES_STANERDIZE,
  DELETE_QUES_STANERDIZE,
  SELECT_QUES_STANERDIZE,
  userRequest,
} from "../../apiRouts/apiRouts";
import { toast } from "react-toastify";

export const getQuestionStd = async (dispatch) => {
  dispatch(getAdminStanderdizeStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_STANERDIZE}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminStanderdizeSuccess(res.data.payload));
    return res
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminStanderdizeFailure());
    return error
  }
};

export const createQuestionStd = async (dispatch, inputData) => {
  dispatch(addAdminStanderdizeStart());
  try {
    console.log("result from createQuestionSH fnction", inputData);
    const result = await userRequest.post(`${ADD_QUES_STANERDIZE}`, inputData);
    dispatch(addAdminStanderdizeSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdminStanderdizeFailure());
  }
};
export const updateQuestionStd = async (userData, dispatch) => {
  dispatch(updateAdminStanderdizeStart());
  console.log("userData >>>", userData);
  try {
    const res = await userRequest.post(`${UPDATE_QUES_STANERDIZE}`, userData);
    console.log("response from updtae defect handling question", res);
    dispatch(updateAdminStanderdizeSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminStanderdizeFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionStd = async (id, dispatch) => {
  dispatch(deleteAdminStanderdizeStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_STANERDIZE}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminStanderdizeSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminStanderdizeFailure());
    toast.error("Server Error");
  }
};
