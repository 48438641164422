import React, { useState  , useRef} from "react";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Legends from "../../components/Legends/Legends";
import { handleAuditExecutionShineObject } from "../../Store/redux-api/GlobalApiCalls";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { getQuestionSH } from "../../Store/redux-api/AdminShApiCalls";

import { toast } from "react-toastify";
import Spinner from "../../components/Spinner/Spinner";

const columns = [
  { id: "sNo", label: "S.No", minWidth: 50 },
  { id: "checkPoints", label: "CheckPoints", minWidth: 100 },
  { id: "score", label: "Score", minWidth: 200 },
];

const Shine = () => {
  const refSubmit = useRef()
  const ToggleButtons = (cp) => {
    return (
      <>
        <FormControl className="radiosize">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            // value='khan'
            name="radio-buttons-group"
            className="d-flex flex-row"
            onChange={(e) => handletRadio(e, cp.cp)}
          >
            <FormControlLabel
              value="1"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="1"
            />
            <FormControlLabel
              value="2"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="2"
            />
            <FormControlLabel
              value="3"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="3"
            />
            <FormControlLabel
              name="chkPassPort"
              value="4"
              control={<Radio color="primary" />}
              label="4"
            />
            <FormControlLabel
              value="5"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="5"
            />
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  let userInputAndCp = [];
  const handletRadio = (e, cp) => {
    let u;

    if (userInputAndCp.length > 0) {
      let i = userInputAndCp.findIndex((x) => x.checkpoint === cp);

      if (i > -1) {
        userInputAndCp.splice(i, 1, {
          checkpoint: cp,
          value: e.target.value,
        });
      } else {
        userInputAndCp.push({
          checkpoint: cp,
          value: e.target.value,
        });
      }
    } else {
      userInputAndCp.push({
        checkpoint: cp,
        value: e.target.value,
      });

    }
    userInputAndCp.length === ShineCheckPointsData.length
      ? refSubmit.current.removeAttribute("disabled")
      : refSubmit.current.setAttribute("disabled", true);
  };

  const getGlobalButton = useSelector(
    (state) => state.globalButtonState.globalButtonState
  );
  const getGlobalData = useSelector((state) => state.globalState.globalState);
  console.log("getGlobalData1 >>>>", getGlobalData);
  const ShineCheckPoints = useSelector((state) => state.adminSH.AdminSH);
  const ShineCheckPointsData = ShineCheckPoints.map((o) => ({ ...o }));

  const [loader, setIsLoader] = useState(false);

  const [myBool, setmyBool] = useState(true);

  function toggleBool() {
    setmyBool(!myBool);
  }

  const history = useHistory();

  if (getGlobalData && getGlobalData.length === 0) {
    setTimeout(() => {
      history.push("/dashboard/user/sort");
    }, 2000);
  }

  const dispatch = useDispatch();
  React.useEffect(() => {
    const getQuestion = async () => {
      setIsLoader(true);

      let resOfGetQuestion = await getQuestionSH(dispatch);

      if (resOfGetQuestion.data.success) {
        setIsLoader(false);
      }
    };
    getQuestion();
  }, [dispatch]);
  let newRows = ShineCheckPointsData.map((item, index) => {
    return {
      sNo: index + 1,
      checkPoints: item.Question,
      score: <ToggleButtons cp={item.Question} />,
    };
  });

  const handleNext = () => {
    if (userInputAndCp.length != ShineCheckPointsData.length) {
      toast.warn("Kindly fill all the questions");
      return;
    }
    handleAuditExecutionShineObject(dispatch, {
      systemName: "Shine",
      checkPoints: userInputAndCp,
    });
    history.push("/dashboard/user/standardize");
  };
  return (
    <div className="container-fluid  p-0 ">
      <div className="row ">
        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 p-0 pe-lg-2 my-1">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {getGlobalData.length > 0 ? (
              <>
                {loader ? (
                  <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "80vh" }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      style={{ height: "300px" }}
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              className="forbackgroundcolor text-uppercase"
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {newRows.map((row, index) => {
                          return (
                            <TableRow
                              className="fortextsize"
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.sNo}
                            >
                              {columns.map((column, index) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    className="fortextsize"
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <div className=" text-end">
                  <button
                    className="btn-icon-text px-3 py-1 rounded-1 mx-2 my-2 btn btn-white bg-success text-white "
                    onClick={handleNext}
                    ref={ refSubmit}
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <div
                style={{
                  height: "350px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="d-flex justify-content-center align-items-md-center"
              >
                To Fill Shine Previous "S" Should be Filled..
              </div>
            )}
          </Paper>
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 pe-0 p-0  my-1 forpopup">
          <Legends  />
        </div>
      </div>
    </div>
  );
};

export default Shine;
