import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import jwt from "jsonwebtoken";

const Home = () => {
  const match = useRouteMatch();

  var decoded;
  function decoder() {
    let token = localStorage.getItem("token");
    decoded = jwt.decode(token, { complete: true });
    console.log("decoded >>>", decoded);

    if (decoded == null) {
      console.log("not found ");
    } else {
      localStorage.setItem("username", decoded.payload.username);
      let user = localStorage.getItem("username");
      return user;
    }
  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    let decoded = jwt.decode(token, { complete: true });
    decoder();
  }, []);

  return (
    <div className="material-table-parent-div material-table-parent-home p-3">
      {/* <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 my-3  mg-b-5">
            Welcome to 5S Audit
          </h2>
        </div>
      </div> */}
      {/* Extra sesssion */}

      <div className="row row-sm  mt-lg-4 " style={{ cursor: 'default' }} >
        <div className="col-sm-12 col-lg-12 col-xl-12" style={{ cursor: 'default' }}>
          <div className="card custom-card card-box" style={{ background: "#76B2B3", cursor: 'default' }}>
            {" "}
            <div className="card-body p-4" style={{ cursor: 'default' }}>
              <div className="row align-items-center" style={{ cursor: 'default' }}>
                {" "}
                <div className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg " style={{ cursor: 'default' }}>
                  {" "}
                  <h4 className="d-flex justify-content-center mb-3">
                    {" "}
                    <span className="font-weight-bold text-white ">
                      <span style={{ color: "#112564" }}> {decoder().toUpperCase()}!</span> Welcome to 5S Audit
                    </span>{" "}
                  </h4>{" "}
                  <p className="tx-white-7 mb-1 text-center">
                    Your Standerds Begin here!
                  </p>
                </div>
                {/* <img
                  src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
                  alt="user-img"
                />{" "} */}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>

      <div
        className="d-flex justify-content-center align-items-center flex-wrap w-100"
        style={{ height: "60vh" }}
      >
        <div className=" d-flex  w-100 py-2 text-center mobile-responsive-width">
          <div className="custom-card card-dashboard mx-2 w-100">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/feedback"}
            >
              <div className="card-body">
                <div className="card-order py-5 ">
                  <label className="main-content-label">
                    {/* <AttractionsIcon />  */}
                    <img
                      src="/icons/dh.png"
                      alt="Center Line"
                      width="75"
                      height="75"
                    />
                    <br />
                    <h5 className="py-2 fw-bold mobile-screen-h5">View 5S</h5>
                  </label>
                </div>
              </div>
            </Link>
          </div>

          <div className="custom-card card-dashboard mx-2 w-100">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/user/sort"}
            >
              <div className="card-body">
                <div className="card-order py-5 ">
                  <label className="main-content-label">
                    {/* <ArticleIcon /> Daily Direction Setting{" "} */}
                    {/* <GroupsIcon /> */}
                    <img
                      src="/icons/dds.png"
                      // className="w-25"
                      alt="Center Line"
                      width="75"
                      height="75"
                    />
                    <br />
                    <h5 className="py-2 fw-bold mobile-screen-h5">Submit 5S</h5>
                  </label>
                </div>
              </div>
            </Link>
          </div>

          <div className="custom-card card-dashboard mx-2 w-100">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/Admin/AdminSort"}
            >
              <div className="card-body">
                <div className="card-order  py-5">
                  <label className="main-content-label w-100">
                    {/* <AutoAwesomeMosaicIcon /> */}
                    <img
                      src="/icons/mps.png"
                      // className="w-25"
                      alt="Center Line"
                      width="75"
                      height="75"
                    />
                    <br />
                    <h5 className="py-2 fw-bold mobile-screen-h5">Administration</h5>
                  </label>
                </div>
              </div>
            </Link>
          </div>
          <div className="custom-card card-dashboard mx-2 w-100">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/AnalyticalDashboard"}
            >
              <div className="card-body">
                <div className="card-order  py-5">
                  <label className="main-content-label w-100">
                    {/* <AutoAwesomeMosaicIcon /> */}
                    <img
                      src="/icons/Analytics.png"
                      // className="w-25"
                      alt="Center Line"
                      width="75"
                      height="75"
                    />
                    <br />
                    <h5 className="py-2 fw-bold mobile-screen-h5">AnalyticalDashboard</h5>
                  </label>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
