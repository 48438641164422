import React from 'react';
import AdminFiveSAudit from '../../containers/FiveSAudit/AdminFiveSAudit';
// import { Outlet } from 'react-router-dom';

const Admin = () => {
  return (
    <div>
      <AdminFiveSAudit />
    </div>

  )
}

export default Admin