import React from 'react';
// import { Outlet } from 'react-router-dom';
import FiveSAudit from '../../containers/FiveSAudit/FiveSAudit'

const User = () => {
  return (
    <>
      <FiveSAudit />
    </>

  )
}

export default User