import { createSlice } from "@reduxjs/toolkit";

const userSHSlice = createSlice({
  name: "userSH",
  initialState: {
    userSH: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET UserSH
    getUserSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserSHSuccess: (state, action) => {
      state.isFetching = false;
      state.userSH = action.payload;
    },
    getUserSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE UserSH
    deleteUserSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserSHSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE UserSH
    updateUserSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserSHSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD UserSH
    addUserSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserSHSuccess: (state, action) => {
      state.isFetching = false;
      // state.userSH.push(action.payload);
    },
    addUserSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserSHStart,
  getUserSHSuccess,
  getUserSHFailure,
  deleteUserSHStart,
  deleteUserSHSuccess,
  deleteUserSHFailure,
  updateUserSHStart,
  updateUserSHSuccess,
  updateUserSHFailure,
  addUserSHStart,
  addUserSHSuccess,
  addUserSHFailure,
} = userSHSlice.actions;
export default userSHSlice.reducer;
