import { createSlice } from "@reduxjs/toolkit";

const AdminSetInOrderSlice = createSlice({
  name: "AdminSetInOrder",
  initialState: {
    AdminSetInOrder: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCL
    getAdminSetInOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminSetInOrderSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminSetInOrder = action.payload;
    },
    getAdminSetInOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCL
    deleteAdminSetInOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminSetInOrderSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminSetInOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCL
    updateAdminSetInOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminSetInOrderSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminSetInOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCL
    addAdminSetInOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdminSetInOrderSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCL.push(action.payload);
    },
    addAdminSetInOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminSetInOrderStart,
  getAdminSetInOrderSuccess,
  getAdminSetInOrderFailure,
  deleteAdminSetInOrderStart,
  deleteAdminSetInOrderSuccess,
  deleteAdminSetInOrderFailure,
  updateAdminSetInOrderStart,
  updateAdminSetInOrderSuccess,
  updateAdminSetInOrderFailure,
  addAdminSetInOrderStart,
  addAdminSetInOrderSuccess,
  addAdminSetInOrderFailure,
} = AdminSetInOrderSlice.actions;
export default AdminSetInOrderSlice.reducer;
