import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Legends from "../../components/Legends/Legends";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAuditExecutionObject,
  handleAuditExecutionSetInOrderObject,
} from "../../Store/redux-api/GlobalApiCalls";

import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getQuestionSIN } from "../../Store/redux-api/AdminSinApiCalls";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner/Spinner";

const columns = [
  { id: "sNo", label: "S.No", minWidth: 50 },
  { id: "checkPoints", label: "CheckPoints", minWidth: 100 },
  { id: "score", label: "Score", minWidth: 200 },
];

const SetInOrder = () => {
  const ToggleButtons = (cp) => {
    return (
      <>
        <FormControl className="radiosize">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            // value='khan'
            name="radio-buttons-group"
            className="d-flex flex-row"
            onChange={(e) => handletRadio(e, cp.cp)}
          >
            <FormControlLabel
              value="1"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="1"
            />
            <FormControlLabel
              value="2"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="2"
            />
            <FormControlLabel
              value="3"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="3"
            />
            <FormControlLabel
              name="chkPassPort"
              value="4"
              control={<Radio color="primary" />}
              label="4"
            />
            <FormControlLabel
              value="5"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="5"
            />
          </RadioGroup>
        </FormControl>
      </>
    );
  };
  const refSubmit = useRef();

  let userInputAndCp = [];
  const handletRadio = (e, cp) => {
    let u;
    // console.log(e.target.value, cp);
    if (userInputAndCp.length > 0) {
      // console.log(userInputAndCp);
      let i = userInputAndCp.findIndex((x) => x.checkpoint === cp);
      // console.log(i);
      if (i > -1) {
        userInputAndCp.splice(i, 1, {
          checkpoint: cp,
          value: e.target.value,
        });
      } else {
        userInputAndCp.push({
          checkpoint: cp,
          value: e.target.value,
        });
      }
      // console.log("userInputAndCp",userInputAndCp);
    } else {
      userInputAndCp.push({
        checkpoint: cp,
        value: e.target.value,
      });
    }
    userInputAndCp.length === SetInOrderCheckPointsData.length
      ? refSubmit.current.removeAttribute("disabled")
      : refSubmit.current.setAttribute("disabled", true);
  };

  const getGlobalButton = useSelector(
    (state) => state.globalButtonState.globalButtonState
  );

  const getGlobalData = useSelector((state) => state.globalState.globalState);

  const SetInOrderCheckPoints = useSelector(
    (state) => state.AdminSetInOrder.AdminSetInOrder
  );
  const SetInOrderCheckPointsData = SetInOrderCheckPoints.map((o) => ({
    ...o,
  }));
  const [myBool, setmyBool] = useState(true);
  const [loader, setIsLoader] = useState(false);

  const history = useHistory();
  console.log("getGlobalData=====>", getGlobalData);
  if (getGlobalData && getGlobalData.length === 0) {
    setTimeout(() => {
      history.push("/dashboard/user/sort");
    }, 2000);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    const getQuestion = async () => {
      // loader state true
      setIsLoader(true);

      let resOfGetQuestion = await getQuestionSIN(dispatch);
      if (resOfGetQuestion.data.success) {
        setIsLoader(false);
      }
    };
    getQuestion();
  }, [dispatch]);
  let newRows = SetInOrderCheckPointsData.map((item, index) => {
    return {
      sNo: index + 1,
      checkPoints: item.Question,

      score: <ToggleButtons cp={item.Question} />,
    };
  });
  const handleNext = () => {
    if (userInputAndCp.length != SetInOrderCheckPointsData.length) {
      toast.warn("Kindly fill all the questions");
      return;
    }
    handleAuditExecutionSetInOrderObject(dispatch, {
      systemName: "SetInorder",
      checkPoints: userInputAndCp,
    });
    history.push("/dashboard/user/shine");
  };

  return (
    <div className="container-fluid  p-0 ">
      <div className="row ">
        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 p-0 pe-lg-2 my-1">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {getGlobalData.length > 0 ? (
              <>
                {loader ? (
                  <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "80vh" }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <TableContainer
                  // sx={{ maxHeight: 440 }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      style={{ height: "300px" }}
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              className="forbackgroundcolor text-uppercase"
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {newRows.map((row, index) => {
                          return (
                            <TableRow
                              className="fortextsize"
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.sNo}
                            >
                              {columns.map((column, index) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    className="fortextsize"
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <div className=" text-end">
                  <button
                    className="btn-icon-text px-3 py-1 rounded-1 mx-2 my-2 btn btn-white bg-success text-white "
                    onClick={handleNext}
                    ref={refSubmit}
                    // style={
                    //     location.pathname==="user/sort "  ? location.pathname=== 'user/shine' : {}

                    // }
                    // disabled={isTrue}
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <div
                style={{
                  height: "350px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="d-flex justify-content-center align-items-md-center"
              >
                To Fill SetInOrder Previous "S" Should be Filled..
              </div>
            )}
          </Paper>
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 pe-0 p-0  my-1 forpopup">
          <Legends />
        </div>
      </div>
    </div>
  );
};

export default SetInOrder;
