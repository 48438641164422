import {
  getAdminSortStart,
  getAdminSortSuccess,
  getAdminSortFailure,
  deleteAdminSortStart,
  deleteAdminSortSuccess,
  deleteAdminSortFailure,
  updateAdminSortStart,
  updateAdminSortSuccess,
  updateAdminSortFailure,
  addAdminSortStart,
  addAdminSortSuccess,
  addAdminSortFailure,
} from "../reducers/AdminSortReducer";
import {
  ADD_QUES_SORT,
  UPDATE_QUES_SORT,
  DELETE_QUES_SORT,
  SELECT_QUES_SORT,
  userRequest,
} from "../../apiRouts/apiRouts";
import { toast } from "react-toastify";

export const getQuestionSort = async (dispatch) => {
  dispatch(getAdminSortStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_SORT}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminSortSuccess(res.data.payload));
    return res
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminSortFailure());
    return error
  }
};

export const createQuestionSort = async (dispatch, inputData) => {
  dispatch(addAdminSortStart());
  try {
    console.log("result from createQuestionSH fnction", inputData);
    const result = await userRequest.post(`${ADD_QUES_SORT}`, inputData);
    dispatch(addAdminSortSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdminSortFailure());
  }
};
export const updateQuestionSort = async (userData, dispatch) => {
  dispatch(updateAdminSortStart());
  console.log("userData >>>", userData);
  try {
    const res = await userRequest.post(`${UPDATE_QUES_SORT}`, userData);
    console.log("response from updtae defect handling question", res);
    dispatch(updateAdminSortSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminSortFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionSort = async (id, dispatch) => {
  dispatch(deleteAdminSortStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_SORT}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminSortSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminSortFailure());
    toast.error("Server Error");
  }
};
