import React, { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./header.css";
import { SELECT_AREA, userRequest } from "../../apiRouts/apiRouts"
import { useDispatch } from 'react-redux'
import { handleAuditExecutionObject } from "../../Store/redux-api/GlobalApiCalls";
import {  useSelector } from "react-redux";



const Header = () => {
  let getGlobalData = useSelector((state) => state.globalState.globalState);
    useEffect(() =>{
        if(getGlobalData.length=== 0) {
          setAreaName("")
        }
    } , [getGlobalData])
    
  const [areaName, setAreaName] = useState("");
  const [areaData, setAreaData] = useState([]);
  const getAreaData = async () => {
    let result = await userRequest.get(`${SELECT_AREA}`);
    
    setAreaData(result.data.payload);
    
  }
   const dispatch = useDispatch()
  const handleChangeArea = (e) => {
    setAreaName(e.target.value)
    let userInput = {
      areaName: e.target.value
    }
    handleAuditExecutionObject(dispatch, userInput)
  }
  

  useEffect(() => {
    getAreaData();
  }, [])
  return (
    <div className="mx-2">
      <Card className="d-flex align-items-center justify-content-center header__card">
        <div className="w-100 h-100 d-flex justify-content-between">
          <div className="w-50 d-flex my-1">
            <Link to={"/dashboard"} className="text-decoration-none text-dark">
              <h2 >5S Audit Execution</h2>
            </Link>
          </div>
          <div className="widthcontroling align-self-center">

            <select
              className="form-select "
              aria-label="Default select example"
              value={areaName}
              onChange={(e) => handleChangeArea(e)}
            >
              <option>Select Area </option>
              {areaData.map((item) => {
                return <option
                  key={item.id}

                >
                  {item.department}
                </option>;
              })}
            </select>
           

          </div>
        </div>
      </Card>
    </div>
  );
};

export default Header;
