import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
// import AdminShineTableComp from "../../components/TableComp/AdminShineTableComp";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import CircularProgress from "@mui/material/CircularProgress";

import {
  createQuestionStd,
  deleteQuestionStd,
  getQuestionStd,
  updateQuestionStd,
} from "../../Store/redux-api/AdminStdApiCalls";
import { sanitizeHtml } from "../../domUtils/domPurify";

const headerStyleObj = {
  backgroundColor: "#002564",
  color: "#EAEDF7",
  borderBottom: "#E4E6EF",
  borderBottomWidth: "1px",
  borderBottomStyle: "dashed",
  fontWeight: "600",
  fontSize: "12px",
};

const AdminStandardize = () => {
  const [description, setdescription] = useState("");

  // const [sDescription, setsDescription] = useState("");
  let adminForStd = useSelector((state) => state.AdminStd.AdminStanderdize);
  let DataForAdminStd = adminForStd.map((o) => ({ ...o }));

  console.log(" adminForStd    >>>>>", adminForStd);

  const [show, setShow] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const addData = (event) => {
    event.preventDefault();
  };
  const data = useRef();
  const handleClose = () => setShow(false);

  // Sanitize Inputs from xss attacks
  const sanitizeDescription = sanitizeHtml(description);

  console.log("newdata >>>>>", description);
  const inputData = {
    question: description,
    // sDescriptions: sDescription,
  };

  const handleAddQuestion = async (e) => {
    console.log("inputData >>>", inputData);
    let res = await createQuestionStd(dispatch, inputData);
    setIsRefresh((preValue) => !preValue);
    setdescription("");

    console.log("res >>>>>", res);
  };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const [columns, setColumns] = useState([
    { title: "CheckPoints", field: "Question" },
    // { title: "depart", field: "depart" },
  ]);
  useEffect(() => {
    getQuestionStd(dispatch);
  }, [dispatch, isRefresh]);
  return (
    <div className="container-fluid bg-light rounded p-lg-4">
      <div className=" text-end">
        {" "}
        <button
          type="button"
          className="btn-icon-text px-2 mx-2 rounded-1 my-2 btn btn-white bg-success text-white"
          onClick={handleShow}
        >
          <AddToPhotosOutlinedIcon
            style={{ fontSize: "16px" }}
            className="me-2"
          />
          Add Question
        </button>
      </div>
      <Modal
        style={{ zIndex: "100px" }}
        className="fade dropdown-toggle-split w-100 "
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#002564" }}>
            Add Check Points:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addData}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h5
                  style={{ color: "#002564" }}
                  p={2}
                  className="text-lg-start"
                >
                  CheckPoint<sup>*</sup>
                </h5>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter  CheckPoint"
                name="username"
                // ref={flowChartDescRef}
                value={sanitizeDescription}
                onChange={(e) => setdescription(e.target.value)}
              />
            </Form.Group>

            <div className="d-flex justify-content-between ">
              <Button
                className="w-25 mt-2 "
                onClick={handleClose}
                style={{ backgroundColor: "grey", border: "none" }}
              >
                Close
              </Button>
              <Button
                className="w-25 mt-2 "
                type="submit"
                onClick={handleAddQuestion}
                style={{ backgroundColor: "#002564", border: "none" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="row w-100">
        <div className=" px-2 my-1">
          <div className="material-table-parent-div">
            <MaterialTable
              title={false}
              columns={columns}
              data={DataForAdminStd}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <h1
                      style={{
                        marginTop: "6%",
                        top: "16%",
                        marginLeft: "-70px",
                        textAlign: "center",
                      }}
                    >
                      <CircularProgress />
                    </h1>
                  ),
                },
              }}
              options={{
                headerStyle: headerStyleObj,
                toolbar: false,
                search: false,
                filtering: true,
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...DataForAdminStd];
                      const index = oldData.id;
                      dataUpdate[index] = newData;
                      updateQuestionStd(dataUpdate[index], dispatch);
                      // getOrder(dispatch);
                      getQuestionStd(dispatch);
                      setIsRefresh((preValue) => !preValue);

                      setShow(false);
                      resolve();
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const index = oldData.id;
                      deleteQuestionStd(index, dispatch);
                      setShow(false);
                      getQuestionStd(dispatch);
                      setIsRefresh((preValue) => !preValue);
                      resolve();
                    }, 1000);
                  }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStandardize;
