import { createSlice } from "@reduxjs/toolkit";

const userFeedbaackSlice = createSlice({
  name: "userFeedbaack",
  initialState: {
    userFeedbaack: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET userFeedbaack
    getUserFeedbaackStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserFeedbaackSuccess: (state, action) => {
      state.isFetching = false;
      state.userFeedbaack = action.payload;
    },
    getUserFeedbaackFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE userFeedbaack
    deleteUserFeedbaackStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserFeedbaackSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserFeedbaackFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE userFeedbaack
    updateUserFeedbaackStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserFeedbaackSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserFeedbaackFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD userFeedbaack
    addUserFeedbaackStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserFeedbaackSuccess: (state, action) => {
      state.isFetching = false;
      // state.userFeedbaack.push(action.payload);
    },
    addUserFeedbaackFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserFeedbaackStart,
  getUserFeedbaackSuccess,
  getUserFeedbaackFailure,
  deleteUserFeedbaackStart,
  deleteUserFeedbaackSuccess,
  deleteUserFeedbaackFailure,
  updateUserFeedbaackStart,
  updateUserFeedbaackSuccess,
  updateUserFeedbaackFailure,
  addUserFeedbaackStart,
  addUserFeedbaackSuccess,
  addUserFeedbaackFailure,
} = userFeedbaackSlice.actions;
export default userFeedbaackSlice.reducer;
