export const handlePageRedirect = () => {
  window.location.href = "https://finehhdigital.com/home";
};
export const handleLogoutRedirect = () => {
  window.location.href = "https://finehhdigital.com/signin";
};

export const handleReload = () => {
  window.location.reload();
};
