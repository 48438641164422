import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
// import { Routes, Route } from "react-router-dom";
import Finelogo from "../../images/white.png";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { useState } from "react";
import AttractionsIcon from "@mui/icons-material/Attractions";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
// import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
// import DnsIcon from '@mui/icons-material/Dns';
import BallotIcon from "@mui/icons-material/Ballot";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// import FiveSAudit from '../../containers/FiveSAudit/FiveSAudit';

import Sort from "../../containers/Sort/Sort";
import SetInOrder from "../../containers/SetInOrder/SetInOrder";
import Shine from "../../containers/Shine/Shine";
import Standardize from "../../containers/Standardize/Standardize";
import Sustain from "../../containers/Sustain/Sustain";
import "./Drawer.css";
import User from "../../pages/User/User";
import Home from "../../pages/Homepage/Home";
import Admin from "../../pages/Admin/Admin";
import AdminSort from "../../containers/Sort/AdminSort";
import AdminSetInOrder from "../../containers/SetInOrder/AdminSetInOrder";
import AdminShine from "../../containers/Shine/AdminShine";
import AdminStandardize from "../../containers/Standardize/AdminStandardize";
import AdminSustain from "../../containers/Sustain/AdminSustain";
import {
  handleLogoutRedirect,
  handlePageRedirect,
  handleReload,
} from "../../apiRouts/windowsLocation";
import jwt from "jsonwebtoken";
// import { useNavigate } from "react-router-dom";
import Dashboard from "../../containers/Dashboard/Dashboard";
import AnalyticalDashboard from "../../pages/AnalyticalDashboard/AnalyticalDashboard";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  // useEffect(() => {
  //   console.log("location.pathname >>", location.pathname);
  //   setTimeout(() => {
  //     history.push(location.pathname);
  //   }, 100);
  // }, []);
  //   const match = useMatch();
  const [display, setdisplay] = useState({ color: "white" });

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [role, setRole] = useState();

  const goBack = () => {
    history.goBack();
  };
  const goForward = () => {
    history.goForward();
  };

  useEffect(() => {
    const isRefresh = localStorage.getItem("isRefresh");
    if (isRefresh) {
      setTimeout(() => {
        console.log("realod >>>>");
        localStorage.removeItem("isRefresh");
        handleReload()
      }, 500);
    }
  }, []);

  var decoded;
  // console.log("decoded ==>", decoded);

  function decoder() {
    var token = localStorage.getItem("token");
    decoded = jwt.decode(token, { complete: true });
    if (decoded == null) {
      history.push("/");
      // Navigate("/")
    } else {
      localStorage.setItem("username", decoded.payload.username);
      var user = localStorage.getItem("username");
      return user;
    }
  }

  const logoutHandle = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    if (localStorage.getItem("username")) {
      console.log("here");
    } else {
      handleLogoutRedirect();
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let decoded = jwt.decode(token, { complete: true });
    setRole(decoded.payload.bosRole);
    decoder();
    if (!decoded) {
      return handleLogoutRedirect();
    }
  }, []);

  const drawer = (
    <div className="forborder">
      <Toolbar
        className="text-light   "
        style={{
          backgroundColor: "#002564",
          fontSize: "20px",
          fontWeight: "400",
          // fontFamily:'fantasy'
        }}
      >
        <span
          style={{
            fontFamily: "slovic",
            fontSize: "36px",
            fontWeight: "1000px",
          }}
        >
          5
        </span>
        S Audit Execution
      </Toolbar>
      <Divider />
      <List style={{ fontSize: "14px" }}>
        <div className="dashboard-btn m-3 mt-2" onClick={handlePageRedirect}>
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="/"
            // to={match.url}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                // location.pathname === "/"
                location.pathname === "/" ? display : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
              >
                Home
              </div>
              <div>
                <Typography>
                  {" "}
                  <HomeIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="/"
            to={match.url}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                // location.pathname === "/"
                location.pathname === "/dashboard" ? display : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
              >
                Main Page
              </div>
              <div>
                <Typography>
                  {" "}
                  <BallotIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="/feedback"
            to={match.url + "/feedback"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                // location.pathname === "/feedback"
                location.pathname === "/dashboard/feedback" ? display : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
              >
                View 5S
              </div>
              <div>
                <Typography>
                  {" "}
                  <ReceiptLongIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to='user'
            to={match.url + "/user/sort"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/user" ||
                location.pathname === "/dashboard/user/sort" ||
                location.pathname === "/dashboard/user/setInOrder" ||
                location.pathname === "/dashboard/user/shine" ||
                location.pathname === "/dashboard/user/standardize" ||
                location.pathname === "/dashboard/user/sustain"
                  ? display
                  : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
              >
                Submit 5S
              </div>
              <div>
                <Typography>
                  {" "}
                  <DashboardIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="Admin"
            to={match.url + "/Admin/AdminSort"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              // style={location.pathname === "/Admin" ? display : {}}
              style={
                location.pathname === "/dashboard/Admin" ||
                location.pathname === "/dashboard/Admin/AdminSort" ||
                location.pathname === "/dashboard/Admin/AdminSetInOrder" ||
                location.pathname === "/dashboard/Admin/AdminShine" ||
                location.pathname === "/dashboard/Admin/AdminStandardize" ||
                location.pathname === "/dashboard/Admin/AdminSustain"
                  ? display
                  : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "white" }}
                onClick={handleDrawerToggle}
              >
                Administration
              </div>
              <div>
                <Typography>
                  {" "}
                  <AttractionsIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} style={{ height: "100%" }}>
      <CssBaseline />
      <AppBar
        className="coloring"
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#002564",
          zIndex: 20,
        }}
      >
        <Toolbar className="drawerTogglButton ">
          <IconButton
            className="d-lg-none"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="col-sm-1   navigation-view  onsmallscreen w-auto ">
            <div className="d-flex justify-content-center ">
              <div>
                <Button>
                  <ArrowBackIosIcon
                    style={{ fill: "white" }}
                    sx={{ display: { xs: "none", sm: "inline" } }}
                    onClick={goBack}
                  />
                </Button>
              </div>
              <div>
                <Button>
                  <ArrowForwardIosIcon
                    style={{ fill: "white" }}
                    sx={{ display: { xs: "none", sm: "inline" } }}
                    onClick={goForward}
                  />
                </Button>
              </div>
            </div>
          </div>
          <Typography></Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src={Finelogo}
              className="logosize  "
              style={{ marginLeft: "10px" }}
              width="130px"
              alt="saime"
            />
          </Typography>
          <Typography>{}</Typography>

          <div>
            {role && decoder().toLocaleUpperCase()}
            {/* {decoder().toLocaleUpperCase()} */}
          </div>
          <Button color="inherit" onClick={logoutHandle}>
            <LogoutIcon />
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 0 }}
        aria-label="mailbox folders"
        className="drawerbackground"
      >
        {/* {/ The implementation can be swapped with js to avoid SEO duplication of links. /} */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#002564",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        className="smallscreensizes w-100 "
        style={{
          backgroundColor: "#EAEDF7",
          // backgroundCover: 'cover',
          paddingTop: "10px",
        }}
        component="main"
        sx={{
          flexGrow: 1,
          // p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        {/* <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/feedback" element={<Dashboard />} />
          <Route path="user" element={<User />}>
            <Route path="sort" element={<Sort />} />
            <Route path="setInOrder" element={<SetInOrder />} />
            <Route path="shine" element={<Shine />} />
            <Route path="standardize" element={<Standardize />} />
            <Route path="sustain" element={<Sustain />} />
          </Route>
          <Route path="Admin" element={<Admin />}>
            <Route path="AdminSort" element={<AdminSort />} />
            <Route path="AdminSetInOrder" element={<AdminSetInOrder />} />
            <Route path="AdminShine" element={<AdminShine />} />
            <Route path="AdminStandardize" element={<AdminStandardize />} />
            <Route path="AdminSustain" element={<AdminSustain />} />
          </Route>
          <Route path='admin' element={<Admin />}></Route>
        </Routes> */}

        <Switch>
          <Route exact path={match.url + ""}>
            <Home />
          </Route>
          <Route path={match.url + "/feedback"}>
            <Dashboard />
          </Route>
          <Route path={match.url + "/user"}>
            <User />
          </Route>
          <Route path={match.url + "/Admin"}>
            <Admin />{" "}
          </Route>
          <Route path={match.url + "/AnalyticalDashboard"}>
            <AnalyticalDashboard />{" "}
          </Route>
          <Route path={match.url + "*"}>
            <Home />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
