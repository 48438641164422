import { createSlice } from "@reduxjs/toolkit";

const AdminSustainSlice = createSlice({
  name: "AdminSustain",
  initialState: {
    AdminSustain: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCL
    getAdminSustainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminSustainSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminSustain = action.payload;
    },
    getAdminSustainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCL
    deleteAdminSustainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminSustainSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminSustainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCL
    updateAdminSustainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminSustainSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminSustainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCL
    addAdminSustainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdminSustainSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCL.push(action.payload);
    },
    addAdminSustainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminSustainStart,
  getAdminSustainSuccess,
  getAdminSustainFailure,
  deleteAdminSustainStart,
  deleteAdminSustainSuccess,
  deleteAdminSustainFailure,
  updateAdminSustainStart,
  updateAdminSustainSuccess,
  updateAdminSustainFailure,
  addAdminSustainStart,
  addAdminSustainSuccess,
  addAdminSustainFailure,
} = AdminSustainSlice.actions;
export default AdminSustainSlice.reducer;
