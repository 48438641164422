import { configureStore } from "@reduxjs/toolkit";
import adminShineReducer from "./reducers/AdminShineReducer";
import GlobalReducer from "./reducers/GlobalReducer";
import AdminSetInOrderReducer from "./reducers/AdminSetInOrderReducer";
import AdminSustainReducer from "./reducers/AdminSustainReducer";
// import dhQuestionReducer from "./reducers/dhQuestionReducer";
import AdminSortReducer from "./reducers/AdminSortReducer";
import AdminStanderdizeReducer from "./reducers/AdminStanderdizeReducer";

import userSHReducer from "./reducers/userSHReducer";
import userFeedbackReducer from "./reducers/userFeedbackReducer";

export default configureStore({
  reducer: {
    adminSH: adminShineReducer,
    userSH: userSHReducer,
    globalState: GlobalReducer,
    globalButtonState: GlobalReducer,
    // AdminQuestionDH:dhQuestionReducer,

    // userCL:userCLReducer,
    AdminSetInOrder: AdminSetInOrderReducer,
    // AdminQuestionCL:clQuestionReducer,

    AdminSustain: AdminSustainReducer,

    //Sort
    AdminSort: AdminSortReducer,

    //Standerdize
    AdminStd: AdminStanderdizeReducer,

    // 5S Feedback
    feedback: userFeedbackReducer,
  },
});
