import { createSlice } from "@reduxjs/toolkit";

const AdminStanderdizeSlice = createSlice({
  name: "AdminStanderdize",
  initialState: {
    AdminStanderdize: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCL
    getAdminStanderdizeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminStanderdizeSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminStanderdize = action.payload;
    },
    getAdminStanderdizeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCL
    deleteAdminStanderdizeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminStanderdizeSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminStanderdizeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCL
    updateAdminStanderdizeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminStanderdizeSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminStanderdizeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCL
    addAdminStanderdizeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdminStanderdizeSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCL.push(action.payload);
    },
    addAdminStanderdizeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminStanderdizeStart,
  getAdminStanderdizeSuccess,
  getAdminStanderdizeFailure,
  deleteAdminStanderdizeStart,
  deleteAdminStanderdizeSuccess,
  deleteAdminStanderdizeFailure,
  updateAdminStanderdizeStart,
  updateAdminStanderdizeSuccess,
  updateAdminStanderdizeFailure,
  addAdminStanderdizeStart,
  addAdminStanderdizeSuccess,
  addAdminStanderdizeFailure,
} = AdminStanderdizeSlice.actions;
export default AdminStanderdizeSlice.reducer;
