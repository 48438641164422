import {
    getAdminSHStart,
    getAdminSHSuccess,
    getAdminSHFailure,
    deleteAdminSHStart,
    deleteAdminSHSuccess,
    deleteAdminSHFailure,
    updateAdminSHStart,
    updateAdminSHSuccess,
    updateAdminSHFailure,
    addAdminSHStart,
    addAdminSHSuccess,
    addAdminSHFailure,
  } from "../reducers/AdminShineReducer";
  import {
    ADD_QUES_SHINE,
    UPDATE_QUES_SHINE,
    DELETE_QUES_SHINE,
    SELECT_QUES_SHINE,
    userRequest
  } from "../../apiRouts/apiRouts";
  import { toast } from "react-toastify";
  
  export const getQuestionSH = async (dispatch) => {
    dispatch(getAdminSHStart());
    try {
      const res = await userRequest.get(`${SELECT_QUES_SHINE}`);
      // console.log("response from get DH admin ==>", res);
      dispatch(getAdminSHSuccess(res.data.payload));
      return res
    } catch (error) {
      console.log("error from UserFailure >>>", error);
      dispatch(getAdminSHFailure());
    return error
    }
  };
  
  export const createQuestionSH = async (dispatch, inputData) => {
    dispatch(addAdminSHStart());
    try {
      console.log("result from createQuestionSH fnction",inputData);
      const result = await userRequest.post(`${ADD_QUES_SHINE}`, inputData);
      dispatch(addAdminSHSuccess(result));
      toast.success("Inserted Successfully");
      return result;
    } catch (err) {
      console.log("error from catch", err);
      toast.error("Server Error");
      dispatch(addAdminSHFailure());
    }
  };
  export const updateQuestionSH = async (userData, dispatch) => {
    dispatch(updateAdminSHStart());
    console.log("userData >>>" , userData);
    try {
      const res = await userRequest.post(`${UPDATE_QUES_SHINE}`, userData);
      console.log("response from updtae defect handling question", res);
      dispatch(updateAdminSHSuccess(res.data));
      toast.success("Updtaed Successfully");
    } catch (error) {
      console.log("error from catch block", error);
      dispatch(updateAdminSHFailure());
      toast.error("Server Error");
    }
  };
  export const deleteQuestionSH = async (id, dispatch) => {
    dispatch(deleteAdminSHStart());
    try {
      const res = await userRequest.post(`${DELETE_QUES_SHINE}`, { id });
      // console.log("response from dleete admin question", res);
      dispatch(deleteAdminSHSuccess(res.data));
      toast.success("Deleted Successfully");
    } catch (error) {
      console.log("error from catch block", error);
      dispatch(deleteAdminSHFailure());
      toast.error("Server Error");
    }
  };
  