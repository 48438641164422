import {
  addUserFeedbaackFailure,
  addUserFeedbaackStart,
  addUserFeedbaackSuccess,
  getUserFeedbaackFailure,
  getUserFeedbaackStart,
  getUserFeedbaackSuccess,
} from "../reducers/userFeedbackReducer";
import {
  CREATE_USER_FEEDBACK,
  SELECT_USER_FEEDBACK,
  userRequest,
} from "../../apiRouts/apiRouts";
import { toast } from "react-toastify";

export const getUserFeedback = async (dispatch) => {
  dispatch(getUserFeedbaackStart());
  try {
    const res = await userRequest.get(`${SELECT_USER_FEEDBACK}`);
    console.log("response from get user feedback ==>", res);
    dispatch(getUserFeedbaackSuccess(res.data.payload));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getUserFeedbaackFailure());
  }
};

export const createUserFeedback = async (
  dispatch,
  inputData,
  userId,
  submittedBy,
  submittedDate
) => {
  dispatch(addUserFeedbaackStart());
  console.log("createUserFeedback fnction inputData >>>>", inputData);
  console.log("createUserFeedback fnction userId >>>>", userId);
  console.log("createUserFeedback fnction submittedBy >>>>", submittedBy);
  console.log("createUserFeedback fnction submittedDate >>>", submittedDate);
  try {
    const result = await userRequest.post(
      `${CREATE_USER_FEEDBACK}?userId=${userId}&submittedBy=${submittedBy}&submittedDate=${submittedDate}`,
      inputData
    );
    console.log("result from createUserFeedback", result);
    dispatch(addUserFeedbaackSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addUserFeedbaackFailure());
  }
};
