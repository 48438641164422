import React, { useEffect } from "react";
// import Cards from "../../components/cards/Cards.jsx";
import "./Dashboard.css";
import MaterialTable from "material-table";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserFeedback } from "../../Store/redux-api/UserFeedbackApiCalls.js";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../components/header/AdminHeader";
import _ from "lodash";
import { useState  } from "react";
import {   useLocation } from "react-router-dom";

const headerStyleObj = {
  backgroundColor: "#002564",
  color: "#EAEDF7",
  borderBottom: "#E4E6EF",
  borderBottomWidth: "1px",
  borderBottomStyle: "dashed",
  fontWeight: "600",
  fontSize: "12px",
  // borderTopLeftRadius: "10px",
  // borderTopRightRadius: "10px",
};

const Dashboard = () => {
  const [isRefresh , setIsRefresh] =useState(false)
  
  let getGlobalData = useSelector((state) => state.globalState.globalState);
  console.log("getGlobalData   >>.", getGlobalData);
  const feedbackData = useSelector((state) => state.feedback.userFeedbaack);
  const feedbackDataforMT = feedbackData.map((o) => ({ ...o }));
  console.log(" feedbackDataforMT    >>>>>", feedbackDataforMT);
  const location=useLocation()
  console.log(location);
  useEffect(() =>{
    if(getGlobalData.length=== 0 && location.pathname==='/dashboard/feedback') {
      setIsRefresh((preval) => !preval)

    }

  } , [ getGlobalData])



  var seprateWithUser = [];
  seprateWithUser.push(_.groupBy(feedbackDataforMT, "userId"));
  var newSeparateWithUser = seprateWithUser[0];

  console.log("newSeparateWithUser>>>", newSeparateWithUser);
  let finalScoreArray = [];

  let uniqueUsers = [];
  Object.entries(newSeparateWithUser).forEach((element, index, arr) => {
    console.log("element>>>>", element);
    uniqueUsers.push({
      userId: element[0],
      data: element[1],
    });
  });

  let uniqueAreas = [];
  Object.entries(uniqueUsers).forEach((element, index, arr) => {
    console.log("element2>>>>", element);
    var seprateWithArea = [];
    seprateWithArea.push(_.groupBy(element[1].data, "areaName"));
    Object.entries(seprateWithArea[0]).forEach((area) => {
      // console.log(`area>>>>>${area}`);
      console.log("area>>>", area);
      uniqueAreas.push({
        userId: element[1].userId,
        areaName: area[0],
        data: area[1],
      });
    });

    let uniqueData = [];
    Object.entries(uniqueAreas).forEach((element, index, arr) => {
      // console.log(`element3>>>>>${element}`);
      console.log("element3>>>>", element);
      var seperatWithDate = [];
      seperatWithDate.push(_.groupBy(element[1].data, "submittedDate"));
      Object.entries(seperatWithDate[0]).forEach((date) => {
        uniqueData.push({
          userId: element[1].userId,
          areaName: element[1].areaName,
          submittedDate: date[0],
          data: date[1],
        });
      });
    });
    uniqueData.forEach((unique, index) => {
      let questionArray = unique.data;
      let totalScore = 0;
      let totalQuestion = questionArray.length;
      console.log("unique>>>", unique);
      let scoreData = {};
      questionArray.some((ques, i) => {
        let submittedBy = ques.submittedBy;
        if (ques.answer === 1) {
          totalScore++;
          scoreData = {
            percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
            submittedDate: unique.submittedDate,
            totalScore: totalScore,
            totalQuestion: totalQuestion,
            submittedBy: submittedBy,
          };
        } else if (ques.answer === 2) {
          totalScore += 2;
          scoreData = {
            percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
            submittedDate: unique.submittedDate,
            totalScore: totalScore,
            totalQuestion: totalQuestion,
            submittedBy: submittedBy,
          };
        } else if (ques.answer === 3) {
          totalScore += 3;
          scoreData = {
            percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
            submittedDate: unique.submittedDate,
            totalScore: totalScore,
            totalQuestion: totalQuestion,
            submittedBy: submittedBy,
          };
        } else if (ques.answer === 4) {
          totalScore += 4;
          scoreData = {
            percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
            submittedDate: unique.submittedDate,
            totalScore: totalScore,
            totalQuestion: totalQuestion,
            submittedBy: submittedBy,
          };
        } else if (ques.answer === 5) {
          totalScore += 5;
          scoreData = {
            percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
            submittedDate: unique.submittedDate,
            totalScore: totalScore,
            totalQuestion: totalQuestion,
            submittedBy: submittedBy,
          };
        } else {
          scoreData = {
            percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
            submittedDate: unique.submittedDate,
            totalScore: totalScore,
            totalQuestion: totalQuestion,
            submittedBy: submittedBy,
          };
        }
      });
      console.log("scoreData >>>", scoreData);
      finalScoreArray.push({
        userId: unique.userId,
        areaName: unique.areaName,
        submittedBy: scoreData.submittedBy,
        percentage: scoreData.percentage + "%",
        submittedDate: unique.submittedDate,
        totalScore: scoreData.totalScore,
        totalQuestion: scoreData.totalQuestion,
        data: questionArray,
      });
    });

    console.log("finalScoreArray >>>", finalScoreArray);
  });

  const dispatch = useDispatch();
  const columns = [
    { title: "Area", field: "areaName" },
    { title: "SubmittedBy", field: "submittedBy" },
    { title: "SubmittedDate", field: "submittedDate" },
    // { title: "AuditName", field: "auditName" },
    // { title: "CheckPoint", field: "question" },
    // { title: "Score", field: "percentage" },
  ];

  useEffect(() => {
    getUserFeedback(dispatch);
  }, [ isRefresh ,dispatch]);
  return (
    <div className="">
      <div className="">
        <AdminHeader />
      </div>
      {/* <div className="d-flex justify-content-center align-items-center h-75">
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 h-auto ">
          <Link to="/5sAudit" className="text-decoration-none">
            <Cards className="cursor-pointer" title="5S Audit" icon="" />
          </Link>
        </div>
      </div> */}
      <div className="container p-0">
        <div className="row w-100">
          <div className=" px-2 my-1">
            <div className="material-table-parent-div">
              <MaterialTable
                style={{
                  borderRadius: "10px",
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <h1
                        style={{
                          marginTop: "6%",
                          top: "16%",
                          marginLeft: "-70px",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress />
                      </h1>
                    ),
                  },
                }}
                data={finalScoreArray}
                detailPanel={(rowData) => {
                  console.log("rowData>>>", rowData);
                  return (
                    <div>
                      <MaterialTable
                        columns={[
                          {
                            title: "AuditName",
                            field: "auditName",
                            cellStyle: {
                              fontSize: "12px",
                            },
                            headerStyle: {
                              fontSize: "12px",
                            },
                          },
                          {
                            title: "AuditQuestion",
                            field: "question",
                            cellStyle: {
                              fontSize: "12px",
                            },
                            headerStyle: {
                              fontSize: "12px",
                            },
                          },

                          {
                            title: "Answer",
                            field: "score",
                            cellStyle: {
                              fontSize: "12px",
                            },
                            headerStyle: {
                              fontSize: "12px",
                            },
                          },
                        ]}
                        data={rowData.data}
                        options={{
                          filtering: false,
                          showTitle: false,
                          headerStyle: headerStyleObj,
                          search: false,
                          filtering: true,
                          pageSizeOptions: [
                            5,
                            10,
                            20,
                            {
                              value: rowData.data ? rowData.data.length : [],
                              label: "All",
                            },
                          ],
                        }}
                      />
                    </div>
                  );
                }}
                title={false}
                columns={columns}
                // data={feedbackDataforMT}
                
                options={{
                  headerStyle: headerStyleObj,
                  toolbar: true,
                  search: false,
                  filtering: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
