import React, { Fragment, useEffect } from "react";
// import { Routes, Route } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import "./App.css";

// toastify
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponsiveDrawer from "./components/Drawer/Drawer";
import { TSetter } from "./components/TSetter/TSetter";

function App() {
  useEffect(() => {
    // console.log(
    //   "Initially " + (window.navigator.onLine ? "on" : "off") + "line"
    // );
    window.addEventListener("online", () =>
      toast.success("You Are Online Now")
    );
    window.addEventListener("offline", () =>
      toast.error("Please Check Your Internet")
    );
  });
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={TSetter}></Route>
          <Route path="/dashboard" component={ResponsiveDrawer}></Route>
        </Switch>
      </BrowserRouter>
      {/* <ResponsiveDrawer /> */}
      {/* {user ? <ResponsiveDrawer /> : <TSetter />} */}
      {/* <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="user" element={<User />}>
          <Route path="sort" element={<Sort />} />
          <Route path="setInOrder" element={<SetInOrder />} />
          <Route path="shine" element={<Shine />} />
          <Route path="standardize" element={<Standardize />} />
          <Route path="sustain" element={<Sustain />} />
        </Route>
        <Route path="*" element={<Dashboard />} />
      </Routes> */}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

export default App;
