import { createSlice } from "@reduxjs/toolkit";

const globalStateSlice = createSlice({
  name: "globalState",
  // second:"button" ,
  initialState: {
    globalState: [],
    globalButtonState: [],
  },

  reducers: {
    addglobalStateSuccess: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      console.log('payload >>>>' , payload.areaName);
      console.log("state.globalState>>>>", payload);
      if (state.globalState.length === 0) {
        state.globalState.push({
          payload,
        });
      } else {
        state.globalState.splice(0, 1, payload={
          payload
        });
      }
    },
    addglobalStateSuccessSort: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      console.log("state.globalState>>>>", payload);
      if (state.globalState.length === 1) {
        state.globalState.push({
          payload,
        });
      } else {
        state.globalState.splice(1, 2, action.payload);
      }
    },
    addglobalStateSuccessShine: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      console.log("state.globalState>>>>", payload);
      if (state.globalState.length === 3) {
        state.globalState.push({
          payload,
        });
      } else {
        state.globalState.splice(3, 4, action.payload);
      }
    },
    addglobalStateSuccessStanderd: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      console.log("state.globalState>>>>", payload);
      if (state.globalState.length === 4) {
        state.globalState.push({
          payload,
        });
      } else {
        state.globalState.splice(4, 5, action.payload);
      }
    },
    addglobalStateSuccessSetInOrder: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      console.log("state.globalState>>>>", payload);
      if (state.globalState.length === 2) {
        state.globalState.push({
          payload,
        });
      } else {
        state.globalState.splice(2, 3, action.payload);
      }
    },
    addglobalStateSuccessSustain: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      console.log("state.globalState>>>>", payload);
      if (state.globalState.length === 5) {
        state.globalState.push({
          payload,
        });
      } else {
        state.globalState.splice(5, 6, action.payload);
      }
    },
    emptyGlobalState: (state, action) => {
      state.isFetching = false;
      state.globalState = [];
    },
    addglobalStateSuccessbutton: (state, action) => {
      state.isFetching = false;
      let payload = action.payload;
      // state.globalButtonState = action.payload
      // console.log('payload >>>>' , payload.areaName);
      console.log("state.globalStatebuttonjhvhghgc>>>>", payload);
      // state.globalButtonState.push(...state.globalButtonState, payload)
      state.globalButtonState.push({
        payload,
      });
    },
  },
});

export const {
  addglobalStateSuccess,
  emptyGlobalState,
  addglobalStateSuccessSort,
  addglobalStateSuccessShine,
  addglobalStateSuccessStanderd,
  addglobalStateSuccessSetInOrder,
  addglobalStateSuccessSustain,
  // addglobalStateSuccessButton,
  addglobalStateSuccessbutton,
} = globalStateSlice.actions;
export default globalStateSlice.reducer;
