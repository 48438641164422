import React from 'react'

const AnalyticalDashboard = () => {
    return (
        <div style={{ height: "100vh" }}>
            <iframe
                style={{ height: "100%", width: "100%" }}
                title="FineHC-SQL"
                src="https://app.powerbi.com/view?r=eyJrIjoiM2JmYWE3NTItYjg1Yi00MWYxLWI0YmQtMmU5NjViN2FlMjg1IiwidCI6IjdjNDk0OTczLTBmOWYtNDMxZC05NWI4LTA0NTdmYzJmNzI4OCIsImMiOjl9&pageName=ReportSectionf8acc2c7962d329d3249"
                frameBorder={0}
                allowFullScreen="true"
            >
            </iframe>
        </div>
    )
}

export default AnalyticalDashboard