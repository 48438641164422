
import axios from "axios";


export const domain = "http://localhost:5000/api/";

// export const domain = "https://fivesb.finehhdigital.com/api/";

const token = localStorage.getItem("token");
export const userRequest = axios.create({
  baseURL: domain,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

//for Area
export const SELECT_AREA = domain + "area/selectArea";

//for SHINE ADMIN
export const ADD_QUES_SHINE = domain + "shine/createShine";
export const UPDATE_QUES_SHINE = domain + "shine/updateShine";
export const DELETE_QUES_SHINE = domain + "shine/deleteShine";
export const SELECT_QUES_SHINE = domain + "shine/selectShine";

// //SHINE  FOR USER
// export const CREATE_SHINE_USER = domain + "shine/createShineUser";
// export const SELECT_SHINE_USER = domain + "shine/selectShinetUser";

//for SORT ADMIN
export const ADD_QUES_SORT = domain + "sort/createSort";
export const UPDATE_QUES_SORT = domain + "sort/updateSort";
export const DELETE_QUES_SORT = domain + "sort/deleteSort";
export const SELECT_QUES_SORT = domain + "sort/selectSort";

// //SORT for USER
// export const CREATE_SORT_USER = domain + "sort/createSortUser";
// export const SELECT_SORT_USER = domain + "sort/selectSortUser";

// SUSTAIN for ADMIN
export const ADD_QUES_SUSTAIN = domain + "sustain/createSustain";
export const UPDATE_QUES_SUSTAIN = domain + "sustain/updateSustain";
export const DELETE_QUES_SUSTAIN = domain + "sustain/deleteSustain";
export const SELECT_QUES_SUSTAIN = domain + "sustain/selectSustain";

// //SUSTAIN for USER
// export const CREATE_SUSTAIN_USER = domain + "sustain/createSustainUser";
// export const SELECT_SUSTAIN_USER = domain + "sustain/selectSustainUser";

//for SIN (SETINORDER) ADMIN
export const ADD_QUES_SIN = domain + "setinorder/createSetInOrder";
export const UPDATE_QUES_SIN = domain + "setinorder/updateSetInOrder";
export const DELETE_QUES_SIN = domain + "setinorder/deleteSetInOrder";
export const SELECT_QUES_SIN = domain + "setinorder/selectSetInOrder";

// //USER for (STANERDIZE)
// export const CREATE_STANERDIZE_USER = domain + "standerdize/createSinUser";
// export const SELECT_STANERDIZE_USER = domain + "standerdize/selectSinUser";

//for  (STANERDIZE) ADMIN
export const ADD_QUES_STANERDIZE = domain + "standardize/createStandardize";
export const UPDATE_QUES_STANERDIZE = domain + "standardize/updateStandardize";
export const DELETE_QUES_STANERDIZE = domain + "standardize/deleteStandardize";
export const SELECT_QUES_STANERDIZE = domain + "standardize/selectStandardize";


//select all question for Admin
export const SELECT_QUES_SHADMIN = domain + "Question/getShineQuestion";
export const SELECT_QUES_STADMIN = domain + "Question/getSortQuestion";
export const SELECT_QUES_SSADMIN = domain + "Question/getQuestion";
export const SELECT_QUES_SINADMIN = domain + "Question/getSinQuestion";
export const SELECT_QUES_SDADMIN = domain + "Question/getStanderdizeQuestion";

//USER for FEEDBACK 
export const CREATE_USER_FEEDBACK = domain + "feedback/createUserFeedback";
export const SELECT_USER_FEEDBACK = domain + "feedback/selectUserFeedback";