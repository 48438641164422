import {
  getAdminSetInOrderStart,
  getAdminSetInOrderSuccess,
  getAdminSetInOrderFailure,
  deleteAdminSetInOrderStart,
  deleteAdminSetInOrderSuccess,
  deleteAdminSetInOrderFailure,
  updateAdminSetInOrderStart,
  updateAdminSetInOrderSuccess,
  updateAdminSetInOrderFailure,
  addAdminSetInOrderStart,
  addAdminSetInOrderSuccess,
  addAdminSetInOrderFailure,
} from "../reducers/AdminSetInOrderReducer";
import {
  ADD_QUES_SIN,
  UPDATE_QUES_SIN,
  DELETE_QUES_SIN,
  SELECT_QUES_SIN,
  userRequest,
} from "../../apiRouts/apiRouts";
import { toast } from "react-toastify";

export const getQuestionSIN = async (dispatch) => {
  dispatch(getAdminSetInOrderStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_SIN}`);
    console.log("response from get DH admin ==>", res);
    dispatch(getAdminSetInOrderSuccess(res.data.payload));
    return res
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminSetInOrderFailure());
    return error
  }

};

export const createQuestionSIN = async (dispatch, inputData) => {
  dispatch(addAdminSetInOrderStart());
  try {
    console.log("result from createQuestionSH fnction", inputData);
    const result = await userRequest.post(`${ADD_QUES_SIN}`, inputData);
    dispatch(addAdminSetInOrderSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdminSetInOrderFailure());
  }
};
export const updateQuestionSIN = async (userData, dispatch) => {
  dispatch(updateAdminSetInOrderStart());
  console.log("userData >>>", userData);
  try {
    const res = await userRequest.post(`${UPDATE_QUES_SIN}`, userData);
    console.log("response from updtae defect handling question", res);
    dispatch(updateAdminSetInOrderSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminSetInOrderFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionSIN = async (id, dispatch) => {
  dispatch(deleteAdminSetInOrderStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_SIN}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminSetInOrderSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminSetInOrderFailure());
    toast.error("Server Error");
  }
};
