import {
  getAdminSustainStart,
  getAdminSustainSuccess,
  getAdminSustainFailure,
  deleteAdminSustainStart,
  deleteAdminSustainSuccess,
  deleteAdminSustainFailure,
  updateAdminSustainStart,
  updateAdminSustainSuccess,
  updateAdminSustainFailure,
  addAdminSustainStart,
  addAdminSustainSuccess,
  addAdminSustainFailure,
} from "../reducers/AdminSustainReducer";
import {
  ADD_QUES_SUSTAIN,
  UPDATE_QUES_SUSTAIN,
  DELETE_QUES_SUSTAIN,
  SELECT_QUES_SUSTAIN,
  userRequest,
} from "../../apiRouts/apiRouts";
import { toast } from "react-toastify";

export const getQuestionSustain = async (dispatch) => {
  dispatch(getAdminSustainStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_SUSTAIN}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminSustainSuccess(res.data.payload));
    return res
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminSustainFailure());
  return error
  }
};

export const createQuestionSustain = async (dispatch, inputData) => {
  dispatch(addAdminSustainStart());
  try {
    console.log("result from createQuestionSH fnction", inputData);
    const result = await userRequest.post(`${ADD_QUES_SUSTAIN}`, inputData);
    dispatch(addAdminSustainSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdminSustainFailure());
  }
};
export const updateQuestionSustain = async (userData, dispatch) => {
  dispatch(updateAdminSustainStart());
  console.log("userData >>>", userData);
  try {
    const res = await userRequest.post(`${UPDATE_QUES_SUSTAIN}`, userData);
    console.log("response from updtae defect handling question", res);
    dispatch(updateAdminSustainSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminSustainFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionSustain = async (id, dispatch) => {
  dispatch(deleteAdminSustainStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_SUSTAIN}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminSustainSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminSustainFailure());
    toast.error("Server Error");
  }
};
