import { createSlice } from "@reduxjs/toolkit";

const AdminSHSlice = createSlice({
  name: "AdminSH",
  initialState: {
    AdminSH: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCL
    getAdminSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminSHSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminSH = action.payload;
    },
    getAdminSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCL
    deleteAdminSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminSHSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCL
    updateAdminSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminSHSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCL
    addAdminSHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdminSHSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCL.push(action.payload);
    },
    addAdminSHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminSHStart,
  getAdminSHSuccess,
  getAdminSHFailure,
  deleteAdminSHStart,
  deleteAdminSHSuccess,
  deleteAdminSHFailure,
  updateAdminSHStart,
  updateAdminSHSuccess,
  updateAdminSHFailure,
  addAdminSHStart,
  addAdminSHSuccess,
  addAdminSHFailure,
} = AdminSHSlice.actions;
export default AdminSHSlice.reducer;
