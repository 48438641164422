import { createSlice } from "@reduxjs/toolkit";

const AdminSortSlice = createSlice({
  name: "AdminSort",
  initialState: {
    AdminSort: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCL
    getAdminSortStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminSortSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminSort = action.payload;
    },
    getAdminSortFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCL
    deleteAdminSortStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminSortSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminSortFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCL
    updateAdminSortStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminSortSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminSortFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCL
    addAdminSortStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdminSortSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCL.push(action.payload);
    },
    addAdminSortFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminSortStart,
  getAdminSortSuccess,
  getAdminSortFailure,
  deleteAdminSortStart,
  deleteAdminSortSuccess,
  deleteAdminSortFailure,
  updateAdminSortStart,
  updateAdminSortSuccess,
  updateAdminSortFailure,
  addAdminSortStart,
  addAdminSortSuccess,
  addAdminSortFailure,
} = AdminSortSlice.actions;
export default AdminSortSlice.reducer;
