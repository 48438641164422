import React from "react";
// import { Button } from "react-bootstrap";
import "./buttons.css";
// import { useState } from "react";
import { useLocation } from "react-router-dom";
// import { useState } from "react";

const Buttons = ({ text }) => {
  const location = useLocation();
  // console.log(" >>>"  , location);
  return (
    <button
      className={`buttons__text my-1 btn-bg-color`}
      style={
        (location.pathname === "/dashboard/Admin/AdminStandardize" &&
          text === "Standerdize") ||
          (location.pathname === "/dashboard/Admin/AdminSetInOrder" &&
            text === "Set In Order") ||
          (location.pathname === "/dashboard/Admin/AdminSort" && text === "Sort") ||
          (location.pathname === "/dashboard/Admin/AdminShine" && text === "Shine") ||
          (location.pathname === "/dashboard/Admin/AdminSustain" && text === "Sustain") ||
          (location.pathname === "/dashboard/user/sort" && text === "Sort") ||
          (location.pathname === "/dashboard/user/setInOrder" && text === "Set In Order") ||
          (location.pathname === '/dashboard/user/shine' && text === "Shine") ||
          (location.pathname === "/dashboard/user/standardize" && text === "Standardize") ||
          (location.pathname === "/dashboard/user/sustain" && text === "Sustain")
          ? { color: "white ", backgroundColor: "#73E9C3", cursor: 'pointer', pointerEvents: 'visible' }

          : {}
      }
    >
      {text}
    </button>
  );
};

export default Buttons;
