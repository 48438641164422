import {
  addglobalStateSuccess,
  emptyGlobalState,
} from "../reducers/GlobalReducer";
import { addglobalStateSuccessSort } from "../reducers/GlobalReducer";
import { addglobalStateSuccessShine } from "../reducers/GlobalReducer";
import { addglobalStateSuccessStanderd } from "../reducers/GlobalReducer";
import { addglobalStateSuccessSetInOrder } from "../reducers/GlobalReducer";
import { addglobalStateSuccessSustain } from "../reducers/GlobalReducer";
import { addglobalStateSuccessbutton } from "../reducers/GlobalReducer";
export const handleAuditExecutionObject = async (dispatch, inputData) => {
  console.log("result from createQuestionSH fnction", inputData);
  dispatch(addglobalStateSuccess(inputData));
};
export const handleAuditExecutionSortObject = async (dispatch, inputData) => {
  console.log("result from createQuestionSH fnction", inputData);
  dispatch(addglobalStateSuccessSort(inputData));
};
export const handleAuditExecutionShineObject = async (dispatch, inputData) => {
  console.log("result from createQuestionSH fnction", inputData);
  dispatch(addglobalStateSuccessShine(inputData));
};
export const handleAuditExecutionStanderdizeObject = async (
  dispatch,
  inputData
) => {
  console.log("result from createQuestionSH fnction", inputData);
  dispatch(addglobalStateSuccessStanderd(inputData));
};
export const handleAuditExecutionSetInOrderObject = async (
  dispatch,
  inputData
) => {
  console.log("result from createQuestionSH fnction", inputData);
  dispatch(addglobalStateSuccessSetInOrder(inputData));
};
export const handleAuditExecutionSustainObject = async (
  dispatch,
  inputData
) => {
  console.log("result from createQuestionSH fnction", inputData);
  dispatch(addglobalStateSuccessSustain(inputData));
};
export const handleEmptyGlobalState = async (dispatch) => {
  dispatch(emptyGlobalState());
};
// export const handleEmptyGlobalStateButton = async (dispatch) => {
//   dispatch(addglobalStateSuccessbutton());
// };



export const handleEmptyGlobalStateButton = async (dispatch, inputData) => {
  console.log("result from getradio resultfnction", inputData);
  dispatch(addglobalStateSuccessbutton(inputData));
  // await (console.log("ustadddnb>>>>>>. hjv" ,handleEmptyGlobalStateButton ))
 
};
// export const handleEmptyGlobalStateButton = async (dispatch, inputData)=> new Promise((resolve) => {
 
//   dispatch(addglobalStateSuccessbutton(inputData));
//   // setTimeout(() => resolve(dispatch, inputData), 1000);
//   // setTimeout(() => {
//   //   // Delay this action by one second
//   // console.log("userInputAndCp khansaab >>>", inputData);

    
//   // }, 1000)
// })
  // await (console.log("ustadddnb>>>>>>. hjv" ,handleEmptyGlobalStateButton ))
 


