import React from "react";
import "./Legends.css";
// import * as React from 'react';
import PopoverPositionedExample from './infoLegends'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GTranslateIcon from '@mui/icons-material/GTranslate';


export default function Legends(props) {
  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
 

 
  return (
    <div>



<div><PopoverPositionedExample /> </div>


{/* <div className="legend-bg-2 text-white mt-2" style={{fontSize:'12px' ,lineHeight:'20px'}}>
<ol>
    <li>First you will select Your Area to proceed further.</li>
    <li>Then step by step you can fill the 5S, its necessery to fill  sort  first  then Set In Order,  second and then so on..</li>
    <li>Its necessary to fill all the five questions , if you dont do this the next button will not enable to you
and the process will not proceed. </li>
    <li>when you will complete all 5S the submit button will be enable to you after click on next button as same as previous.</li>
    <li>By clicking the submit button your audit will be submit, you can see your audit on  Veiw all 5S page.</li>
</ol>
    <div className="my-1 text-center">Thank you! </div>
</div> */}


      
  
{/* <button onClick={props.toggleBool}>
  jhvjhv
          </button> */}
    <div className="d-flex legend-bg p-3 mt-2">
      
      <div className="d-flex justify-content-between forFontSize  w-100  ">
     
      <div className=" text-end">
          <div className="mt-3 ">
            {" "}
            <span className="comp-non-comp-heading fs-6">امتثال</span>{" "}
          </div>
          <ul className="p-0 list-inline">
            <li className="mt-3 fs-6"><span className="fw-bold">ثلاثة- &nbsp;</span> بعض الامتثال </li>
            <li className="mt-3 fs-6"><span className="fw-bold">أربعة- </span>   الامتثال الجوهري</li>
            <li className="mt-3 fs-6">  <span className="fw-bold">خمسة- &nbsp;</span> إجمالي الشكوى</li>
          </ul>
        </div>
        <div className=" text-end">
          <div className="mt-3">
            <span className="comp-non-comp-heading fs-6">عدم الامتثال</span>
          </div>
          <ul className="p-0 list-inline">
            <li className="mt-3 fs-6"> <span className="fw-bold">واحد- &nbsp; </span>   عدم الامتثال</li>
            <li className="mt-3 fs-6">   <span className="fw-bold">إثنان- &nbsp; </span> امتثال ضئيل جدا</li>
          </ul>
        </div>
        
      </div>
     
    </div>
    <div className="d-flex legend-bg bg-light p-3 mt-2 mb-2 ">
      
      <div className="d-flex justify-content-between forFontSize text-start w-100">
        

        <div className="">
          <div className="mt-3">
            <span className="comp-non-comp-heading ">Non Compliance</span>
          </div>
          <ul className="p-0 list-inline">
            <li className="mt-3">1- No Compliance</li>
            <li className="mt-3"> 2- Very Little Compliance</li>
          </ul>
        </div>
        <div className="">
          <div className="mt-3">
            {" "}
            <span className="comp-non-comp-heading ">Compliance</span>{" "}
          </div>
          <ul className="p-0 list-inline">
            <li className="mt-3">3- Some Compliance</li>
            <li className="mt-3">4- Significant Compliance</li>
            <li className="mt-3">5- Total Complaince</li>
          </ul>
        </div>
      </div>
     
    </div>
    

</div>
  );
}
