import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { toast } from "react-toastify";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Legends from "../../components/Legends/Legends";
import { useState, useCallback, useMemo } from "react";
import {
  handleAuditExecutionSustainObject,
  handleEmptyGlobalState,
} from "../../Store/redux-api/GlobalApiCalls";
import { useDispatch, useSelector } from "react-redux";
import { createUserFeedback } from "../../Store/redux-api/UserFeedbackApiCalls";
import {
  handleLogoutRedirect,
  handlePageRedirect,
} from "../../apiRouts/windowsLocation";

import jwt from "jsonwebtoken";
import { getQuestionSustain } from "../../Store/redux-api/AdminSusApiCalls";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { useEffect } from "react";
import { useRef } from "react";

const columns = [
  { id: "sNo", label: "S.No", minWidth: 50 },
  { id: "checkPoints", label: "CheckPoints", minWidth: 100 },
  { id: "score", label: "Score", minWidth: 200 },
];

function createData(sNo, checkPoints, score) {
  return { sNo, checkPoints, score };
}
const Sustain = () => {
  const ToggleButtons = (cp) => {
    return (
      <>
        <FormControl className="radiosize" value={sustainObj}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            // value='khan'

            name="radio-buttons-group"
            className="d-flex flex-row"
            onChange={(e) => handletRadio(e, cp.cp)}
          >
            <FormControlLabel
              value="1"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="1"
            />
            <FormControlLabel
              value="2"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="2"
            />
            <FormControlLabel
              value="3"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="3"
            />
            <FormControlLabel
              name="chkPassPort"
              value="4"
              control={<Radio color="primary" />}
              label="4"
            />
            <FormControlLabel
              value="5"
              name="chkPassPort"
              control={<Radio color="primary" />}
              label="5"
            />
          </RadioGroup>
        </FormControl>
      </>
    );
  };
  const [isView, setIsView] = useState(false);
  console.log("is view >>>", isView);
  let userInputAndCp = [];
  const handletRadio = (e, cp) => {
    let u;
    // console.log(e.target.value, cp);
    if (userInputAndCp.length > 0) {
      let i = userInputAndCp.findIndex((x) => x.checkpoint === cp);
      if (i > -1) {
        userInputAndCp.splice(i, 1, {
          checkpoint: cp,
          value: e.target.value,
        });
      } else {
        userInputAndCp.push({
          checkpoint: cp,
          value: e.target.value,
        });
      }
    } else {
      userInputAndCp.push({
        checkpoint: cp,
        value: e.target.value,
      });
    }
    userInputAndCp.length === sustainCheckPointsData.length
      ? refSubmit.current.removeAttribute("disabled")
      : refSubmit.current.setAttribute("disabled", true);
  };

  let getGlobalData = useSelector((state) => state.globalState.globalState);
  console.log("getGlobalData >>>", getGlobalData);
  const sustainCheckPoints = useSelector(
    (state) => state.AdminSustain.AdminSustain
  );
  const sustainCheckPointsData = sustainCheckPoints.map((o) => ({ ...o }));
  const [sustainObj, setSustainObj] = useState({});
  const [userId, setUserId] = useState("");
  const [submittedBy, setSubmittedBy] = useState("");
  const [Refresh, setIsRefresh] = useState(false);
  const [loader, setIsLoader] = useState(false);
  const [loaded, setIsLoaded] = useState(false);
  const history = useHistory();
  if (getGlobalData && getGlobalData.length === 0) {
    setTimeout(() => {
      history.push("/dashboard/user/sort");
    }, 2000);
  }

  // let ref = useRef({display :'none'});
  // console.log(ref.current);
  // const inputRef = useRef(null);
  const buttonRef = useRef(false);
  const refSubmit = useRef();

  const submitRef = useRef(0);

  // console.log("submitRef >>>>", submitRef);
  var display = "none";
  // console.log("display >>>", display);

  const dispatch = useDispatch();
  React.useEffect(() => {
    const getQuestion = async () => {
      // loader state true
      setIsLoaded(true);

      let resOfGetQuestion = await getQuestionSustain(dispatch);

      if (resOfGetQuestion.data.success) {
        setIsLoaded(false);
      }
    };
    getQuestion();
  }, [dispatch]);

  let newRows = sustainCheckPointsData.map((item, index) => {
    return {
      sNo: index + 1,
      checkPoints: item.Question,
      // score: <ToggleButtons />,
      score: <ToggleButtons cp={item.Question} />,
    };
  });
  React.useEffect(() => {
    let token = localStorage.getItem("token");
    let decoded = jwt.decode(token, { complete: true });
    // console.log("token ==>", decoded);
    setUserId(decoded.payload.id);
    setSubmittedBy(decoded.payload.username);
    if (!decoded) {
      return handleLogoutRedirect();
    }
  }, [Refresh]);

  const submittedDate = moment().format("YYYY-MM-DD");
  // let submittedData = [];
  let submittedData = getGlobalData;
  console.log("submittedDta >>>", submittedData);

  const handleNext = () => {
    console.log("getGlobalData >>>", getGlobalData);

    if (userInputAndCp.length != sustainCheckPointsData.length) {
      toast.warn("Kindly fill all the questions");
      return;
    } else {
      // console.log("else block >>>");
      // buttonRef.current.disabled = true;
      // submitRef.current= true;
      refSubmit.current.setAttribute("disabled", true);
      submitRef.current.style.display = "flex";

      //Mudassir Work

      // submitRef.current.disabled = false;
      // console.log("submitRef >>>", submitRef);
      display = "flex";
        if (submittedData.length === 5){
      submittedData = [
        ...submittedData,
        {
          payload: {
            systemName: "Sustain",
            checkPoints: userInputAndCp,
          },
        },
      ];}
      else{
        submittedData.splice(5 , 1 ,  {
          payload: {
            systemName: "Sustain",
            checkPoints: userInputAndCp,
          },
        },)
      }
      console.log("submitted Data >>>", submittedData);
    }
  };
  // }, [dispatch]);
  const handleSubmit = async () => {
    if (sustainCheckPointsData.length === userInputAndCp.length) {
      // handleAuditExecutionSustainObject(dispatch, {
      //   systemName: "Sustain",
      //   checkPoints: userInputAndCp,
      // });
      // submittedData =
      //   getGlobalData +
      //   {
      //     payload: {
      //       systemName: "Sustain",
      //       checkPoints: userInputAndCp,
      //     },
      //   };
      setIsLoader(true);
    }
    console.log("submitted Data >>>", submittedData);
    const res = await createUserFeedback(
      dispatch,
      // getGlobalData,
      submittedData,
      userId,
      submittedBy,
      submittedDate
    );

    if (res.status === 200) {
      setIsLoader(false);
      setIsRefresh((preval) => !preval);
      handleEmptyGlobalState(dispatch);
      history.push("/dashboard/user/sort");
    } else {
      toast.error("Kindly fill all the questions");
    }
  };
  return (
    <div className="container-fluid d-flex p-0">
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ height: "80vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <div className="row w-100">
          <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 p-0 pe-lg-2 my-1">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {getGlobalData.length > 0 ? (
                <>
                  {loaded ? (
                    <div
                      className="d-flex justify-content-center align-items-center w-100"
                      style={{ height: "80vh" }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        style={{ height: "300px" }}
                      >
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                className="forbackgroundcolor text-uppercase"
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {newRows.map((row, index) => {
                            return (
                              <TableRow
                                className="fortextsize"
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.sNo}
                              >
                                {columns.map((column, index) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      className="fortextsize"
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  <div className=" text-end">
                    <button
                      className="btn-icon-text px-3 py-1 rounded-1 mx-2 my-2 btn btn-white bg-success text-white "
                      onClick={handleNext}
                      ref={refSubmit}
                    >
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    height: "350px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  className="d-flex justify-content-center align-items-md-center"
                >
                 To Fill Sustain Previous "S" Should be Filled..
                </div>
              )}
            </Paper>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 pe-0 p-0 mediumscreen  ">
            {/* {submitRef.current === true ? ( */}
            <div
              ref={submitRef}
              className=" justify-content-center  mb-3 "
              style={{
                // display: getGlobalData.length === 6 ? "flex" : "none",

                display: "none",
                // display: submitRef.current === false ? "flex" : "none",

                // display: display,
                // display:  buttonRef.current && buttonRef.current.disabled === false ? "flex" : "none",
              }}
            >
              <div className=" text-lg-end text-center   Discardbutton  ">
                <button
                  // className="btn-icon-text px-3 py-1 rounder-0 mx-2 my-2 btn  text-white "
                  className="btn-icon-text px-2 mx-2 rounded-1 my-2 btn btn-white  text-white"
                  // ref={!buttonRef}
                  // ref={submitRef}
                  // disabled={submitRef}
                >
                  Discard
                </button>
              </div>
              <div className=" text-lg-end text-center buttons   ">
                <button
                  className="btn-icon-text px-2 rounded-1 mx-2 my-2 btn btn-white bg-success text-white"
                  onClick={handleSubmit}
                  // disabled={isTrue}
                  // ref={!buttonRef}
                  // ref={submitRef}
                  // ref={submitRef}
                  // disabled={submitRef.current}
                >
                  Submit
                </button>
                {/* <Buttons text="Submit" /> */}
              </div>
            </div>
            {/* ) : null} */}

            <Legends />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sustain;
